import React from 'react';
import "./SaltybridgeSection20.scss"
import "../../../scss/components/saltybridge/heading.scss"

import {Img} from 'react-image'
import {useTranslation, Trans} from 'gatsby-plugin-react-i18next'

import img_1 from '../../../images/ortho/Photo-by-Enis-Yavuz-on-Unsplash.png';

export default function SaltybridegSection20(){
  const {t} = useTranslation()

    return <div id="sbcom-orth9">
  <div className="container">
    <div className="div2">
      <div className="wrap">
        <div className="img-div">
          <Img className="article-img" src={img_1}></Img>
        </div>
      </div>
    </div>
    <div className="div1">
      <div className="wrap">
        <div className="kockica"></div>
        <div className="naslov">
          <h2 className="special-title">{t('sbcom_ss20_ortho_text1')}</h2>
          <div className="line-span"></div>
        </div>
        <div className="tekst">
          <p className="lead">{t('sbcom_ss20_ortho_text2')}</p>
        </div>
        <div className="kockica2"></div>
      </div>       
    </div>
  </div>
</div>
}