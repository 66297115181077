import React from 'react'

import HeaderPage from '../components/HeaderPage/HeaderPage'
import Footer from '../components/Footer/Footer'

import SaltybridgeLoader from '../components/sb/SaltybridgeLoader'
import SaltybridgeTopMenu from '../components/sb/SaltybridgeTopMenu'

import SaltybridegSection1ortho from '../components/sb/SaltybridegSection1ortho'
import SaltybridegSection2ortho from '../components/sb/SaltybridegSection2ortho'
import SaltybridegSection19 from '../components/sb/SaltybridegSection19'
import SaltybridegSection20 from '../components/sb/SaltybridegSection20'
import SaltybridegSection11 from '../components/sb/SaltybridegSection11'

import img_0 from '../images/ortho/img_bg_1.jpg'

import GoToTopButton from '../components/GoToTop'
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby';
import { SEO } from "../components/SEO/SEO"

class Orthodontics extends React.Component {
    constructor (props){
      super(props);
      this.topMenuRef = React.createRef()
      this.scrollTo = this.scrollTo.bind(this)
    }
    
    scrollTo() {
      if (!this.topMenuRef.current) return;
      this.topMenuRef.current.scrollIntoView({ behavior: "smooth", block:"center"});
    }
        render() {
          const { location } = this.props;
          const putanja = location.pathname;
          const t = this.props.t
            return ( 
                <div className="Main">
                <SaltybridgeLoader/>
            
                <div id="page">
                <SaltybridgeTopMenu pathname={putanja} onContactUsClick={this.scrollTo}/>
                <HeaderPage name={t("sbcom_header_page_orthodontics")} image={img_0}/>
                <SaltybridegSection20/>
                <SaltybridegSection19/>
                <SaltybridegSection1ortho/>
                <SaltybridegSection2ortho/>
                <SaltybridegSection11 ref={this.topMenuRef}/>
                <Footer/>
                </div>

                <GoToTopButton/>
                </div>
            );
        }
    }

export default withTranslation()(Orthodontics);

export const Head = () => (
  <SEO />
)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;