import "./SaltybridgeSection1ortho.scss"
import "../../../scss/components/saltybridge/heading.scss"

import React from 'react';
import {Img} from 'react-image'
import {useTranslation, Trans} from 'gatsby-plugin-react-i18next'

import img_7 from '../../../images/ortho/orth-2-b.png';
import img_6 from '../../../images/ortho/orth-6.png';
import img_5 from '../../../images/ortho/orth-5.png';
import img_4 from '../../../images/ortho/orth-4.png';
import img_3 from '../../../images/ortho/orth-3.png';
import img_2 from '../../../images/ortho/orth-2.png';
import img_1 from '../../../images/ortho/orth-1.png';

export default function SaltybridegSection1ortho(){
  const {t} = useTranslation()

    return <div id="sbcom-project">
    <div className="container">
      <div className="row row-pb-md">
        <div className="col-md-8 col-md-offset-2 text-left sbcom-heading  animate-box">
          <span style={{fontSize: "1rem"}}>{t('sbcom_ss1_ortho_text1')}</span>
          <h2>{t('sbcom_ss1_ortho_text2')}</h2>
          <p style={{fontSize: "1.25rem"}}>{t('sbcom_ss1_ortho_text3')}</p>
        </div>
      </div>
    
      <div className="row">
        <div className="col-md-4 col-sm-6 sbcom-project animate-box" data-animate-effect="fadeIn">
          <a to="#"><Img src={img_4} alt="HTML5 Template by saltybridge.com" className="img-responsive"/>
            <div className="sbcom-copy">
              <h3>{t('sbcom_ss1_ortho_project_text3')}</h3>
              <p>{t('sbcom_ss1_ortho_project_desc3')}</p>
            </div>
          </a>
        </div>
        {/*
        <div className="col-md-4 col-sm-6 sbcom-project animate-box" data-animate-effect="fadeIn">
          <a to="#"><Img src={img_5} alt="HTML5 Template by saltybridge.com" className="img-responsive"/>
            <div className="sbcom-copy">
              <h3>{t('sbcom_ss1_ortho_project_text4')}</h3>
              <p>{t('sbcom_ss1_ortho_project_desc4')}</p>
            </div>
          </a>
        </div>
        */}
        <div className="col-md-4 col-sm-6 sbcom-project animate-box" data-animate-effect="fadeIn">
          <a to="#"><Img src={img_5} alt="HTML5 Template by saltybridge.com" className="img-responsive"/>
            <div className="sbcom-copy">
              <h3>{t('sbcom_ss1_ortho_project_text8')}</h3>
              <p></p>
            </div>
          </a>
        </div>
        <div className="col-md-4 col-sm-6 sbcom-project animate-box" data-animate-effect="fadeIn">
          <a to="#"><Img src={img_6} alt="HTML5 Template by saltybridge.com" className="img-responsive"/>
            <div className="sbcom-copy">
              <h3>{t('sbcom_ss1_ortho_project_text1')}</h3>
              <p>{t('sbcom_ss1_ortho_project_desc1')}</p>
            </div>
          </a>
        </div>
    
      </div>

      <div className="row">
        <div className="col-md-4 col-sm-6 sbcom-project animate-box" data-animate-effect="fadeIn">
          <a to="#"><Img src={img_1} alt="HTML5 Template by saltybridge.com" className="img-responsive"/>
            <div className="sbcom-copy">
              <h3>{t('sbcom_ss1_ortho_project_text2')}</h3>
              <p>{t('sbcom_ss1_ortho_project_desc2')}</p>
            </div>
          </a>
        </div>
        <div className="col-md-4 col-sm-6 sbcom-project animate-box" data-animate-effect="fadeIn">
          <a to="#"><Img src={img_2} alt="HTML5 Template by saltybridge.com" className="img-responsive"/>
            <div className="sbcom-copy">
              <h3>{t('sbcom_ss1_ortho_project_text6')}</h3>
              <p>{t('sbcom_ss1_ortho_project_desc6')}</p>
            </div>
          </a>
        </div>
        <div className="col-md-4 col-sm-6 sbcom-project animate-box" data-animate-effect="fadeIn">
          <a to="#"><Img src={img_3} alt="HTML5 Template by saltybridge.com" className="img-responsive"/>
            <div className="sbcom-copy">
              <h3>{t('sbcom_ss1_ortho_project_text5')}</h3>
              <p>{t('sbcom_ss1_ortho_project_desc5')}</p>
            </div>
          </a>
        </div>
    
      </div>
      {/*<div className="row">
        <div className="col-md-4 col-sm-6">
          <p>Different functional appliances are used for treating issues with the jaw and problems with bite.</p>
          <p>Orthodontists know what needs to be done and when, and you will make the best decision together.</p>
        </div>
</div>*/}
      <div className="row">
        <div className="col-md-12">
          <p style={{fontSize: "1.25rem"}}>{t("sbcom_ss1_ortho_text4")}</p>
          <p style={{fontSize: "1.25rem"}}>{t("sbcom_ss1_ortho_text5")}</p>
        </div>
      </div>
      
    </div>
    
    </div>
}





