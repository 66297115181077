import React from 'react';
import "../../../scss/components/saltybridge/heading.scss"
import "../../../scss/components/saltybridge/feature_center.scss"
import "./SaltybridegSection2ortho.scss"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {Img} from 'react-image'

import img_1 from '../../../images/dental/dental-care.png';
import img_2 from '../../../images/dental/teethsgr.png';
import img_3 from '../../../images/dental/toothache.png';
import img_4 from '../../../images/dental/denture.png';
import img_5 from '../../../images/dental/apple.png';
import img_6 from '../../../images/dental/jaw.png';
import img_7 from '../../../images/dental/milk-tooth.png';
import img_8 from '../../../images/dental/face-asymmetry.png';
import img_9 from '../../../images/dental/unhealthy.png';
import img_10 from '../../../images/dental/teeth-br.png';
import img_11 from '../../../images/dental/bad-breath.png';

export default function SaltybridegSection2ortho(){
    const {t} = useTranslation()

    return  <div id="sbcom-services" className="sbcom-bg-section border-bottom">
    <div className="container">
    <div className="row row-pb-md">
        <div className="col-md-8 col-md-offset-2 text-left animate-box" data-animate-effect="fadeInUp">
        <div className="sbcom-heading">
            <span style={{fontSize: "1rem"}}>{t('sbcom_ss2_ortho_text1')}</span>
            <h2>{t('sbcom_ss2_ortho_text2')}</h2>
            <p style={{fontSize: "1.25rem"}}>{t('sbcom_ss2_ortho_text3')}</p>
        </div>
        </div>
    </div>
    <div className="row">
        <div className="col-md-12 ">
            <h3>{t('sbcom_ss2_ortho_text4')}</h3>
        </div>
    </div>
    <div className="row">
        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
                <Img id="bela-slike" src={img_1} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text1')}</h3>
        </div>
        </div>
        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
            <Img id="bela-slike" src={img_7} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text2')}</h3>
        </div>
        </div>
        <div className="clearfix visible-sm-block"></div>
        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
            <Img id="bela-slike" src={img_5} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text3')}</h3>
        </div>
        </div>

        <div className="clearfix visible-md-block"></div>

        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
            <Img id="bela-slike" src={img_11} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text4')}</h3>
        </div>
        </div>
        <div className="clearfix visible-sm-block"></div>
        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
            <Img id="bela-slike" src={img_9} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text5')}</h3>
        </div>
        </div>
        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
            <Img id="bela-slike" src={img_4} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text6')}</h3>
        </div>
        </div>

        <div className="clearfix visible-md-block"></div>

        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
            <Img id="bela-slike" src={img_10} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text7')}</h3>
        </div>
        </div>
        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
            <Img id="bela-slike" src={img_6} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text8')}</h3>
        </div>
        </div>
        <div className="clearfix visible-sm-block"></div>
        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
            <Img id="bela-slike" src={img_3} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text9')}</h3>
        </div>
        </div>

        <div className="clearfix visible-md-block"></div>

        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
            <Img id="bela-slike" src={img_2} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text10')}</h3>
        </div>
        </div>
        <div className="clearfix visible-sm-block"></div>
        <div className="col-md-4 col-sm-6 ">
        <div className="feature-center animate-box" data-animate-effect="fadeInUp" style={{cursor: "pointer"}}>
            <span className="icon">
            <Img id="bela-slike" src={img_8} style={{width: "50px"}}></Img>
            </span>
            <h3>{t('sbcom_ss2_ortho_reason_text11')}</h3>
        </div>
        </div>

        <div className="clearfix visible-md-block"></div>

    </div>
    </div>
</div>
}